import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

const Imprint = () => (
  <Layout lang="en" langPath="nav.imprint.link">
    <Seo
      title="Monday Consulting GmbH – Imprint"
      description="Imprint for the Monday Consulting GmbH website."
      keywords="Imprint"
    />
    <div className="parallax-translate bg-shape-diamond" />
    <div className="container mt-4 mb-4">
      <div className="row">
        <div className="col-12">
          <h1 className="text-size-2 text-size-md-1">Imprint</h1>
        </div>
      </div>
    </div>
    <div className="container mt-4 mb-4 reveal">
      <div className="row">
        <div className="col-12">
          <p>
            Monday Consulting GmbH
            <br />
            Breite Str. 61
            <br />
            22767 Hamburg
            <br />
          </p>
          <p>
            Registered place of business: Hamburg
            <br />
            Court of Registry: Amtsgericht Hamburg
            <br />
            Registry number: HRB 109193
            <br />
            VAT registration number
            <br />
            (section 27a, German Sales Tax Act): DE265148776
          </p>
          <h2 className="h4">Contact</h2>
          <p>
            Phone: +49 (0)40 22 86 842 0<br />
            Fax: +49 (0)40 22 86 842 99
            <br />
            E-Mail:{" "}
            <a href="mailto:contact@monday-consulting.com">
              contact@monday-consulting.com
            </a>
            <br />
            Web: <Link to="/">www.monday-consulting.com</Link>
            <br />
          </p>
          <h4>Executive directors and authorised representatives</h4>
          <p>
            Andreas Bögemann
            <br />
            Marcel Scheland
            <br />
            Dirk Schrödter
            <br />
            Volker Schrödter
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default Imprint;
